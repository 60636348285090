<template>
  <div>
    <div class="card card-custom mb-5 mt-sm-5">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3">
            <MultiselectFormGroup
                class="app-view__form-group"
                :label="$t('label.year')"
                :value="years.find(el => el.id === filters.year)"
                :options="years"
                @select="filters.year = $event.id"
            />
          </div>
          <div class="col-md-7">
            <MultiselectFormGroup
                class="app-view__form-group"
                :label="$t('label.apartments')"
                :value="apartments.filter(el => filters.apartments.includes(el.id))"
                :options="apartments"
                :multiple="true"
                @select="filters.apartments.push($event.id)"
                @remove="filters.apartments = filters.apartments.filter(el => el !== $event.id)"
            />
          </div>
          <div class="col-md-2">
            <button type="button" class="btn btn-success font-weight-bold w-100" @click="search">
              {{ $t('btn.update') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-custom">
      <div class="card-body p-0">
        <b-table v-if="Object.keys(revenue).length" striped hover :items="computedRevenue" :fields="fields">
          <template #cell(bookings)="data">
            <b-badge variant="success" class="cursor-pointer" @click="goToBookingsList(data)">{{ data.value }}</b-badge>
          </template>
          <template #cell(utilities)="data">
            <b-badge variant="primary" class="cursor-pointer" @click="showDetailsModal(data)">{{
                data.value.sum
              }}
            </b-badge>
          </template>
        </b-table>
        <div v-else class="text-center text-muted p-7">
          {{ $t('description.no_data') }}
        </div>
      </div>
    </div>

    <b-modal id="utilitiesDetails"
             :title="$t('title.utilities')"
             centered hide-footer
    >
      <template #modal-title>
        {{ $t('title.utilities') }}
      </template>
      <template #default>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex flex-column">
            <div class="font-size-h4 font-weight-bolder">{{ utilitiesModalData.electricity }}</div>
            <div class="text-muted">{{ $t('label.electricity') }}</div>
          </div>
          <div class="d-flex flex-column">
            <div class="text-center font-size-h4 font-weight-bolder">{{ utilitiesModalData.water }}</div>
            <div class="text-center text-muted">{{ $t('label.water') }}</div>
          </div>
          <div class="d-flex flex-column">
            <div class="text-right font-size-h4 font-weight-bolder">{{ utilitiesModalData.services }}</div>
            <div class="text-right text-muted">{{ $t('label.services') }}</div>
          </div>
        </div>
        <div class="text-right border-top border-top-secondary mt-10 pt-3">
          <span class="text-muted">{{ $t('label.total') }}:</span>
          <span class="font-size-h4 font-weight-bolder ml-1">{{ utilitiesModalData.sum }}</span>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import MultiselectFormGroup from '@/components/elements/form-groups/MultiselectFormGroup';

export default {
  components: {
    MultiselectFormGroup
  },
  data() {
    return {
      // fields: ['N', 'month', 'bookings', 'revenue', 'utilities', 'vat', 'balance'],
      fields: [
        {key: 'N', label: '№'},
        {key: 'month', label: this.$t('label.month')},
        {key: 'bookings', label: this.$t('label.bookings')},
        {key: 'revenue', label: this.$t('label.revenue')},
        {key: 'utilities', label: this.$t('label.utilities')},
        {key: 'vat', label: this.$t('label.vat')},
        {key: 'balance', label: this.$t('label.balance')},
      ],
      filters: {
        year: 2022,
        apartments: []
      },
      utilitiesModalData: null,
      years: [...Array(1)].map((a, b) => {
        let year = new Date().getFullYear() - b;
        return {id: year, label: year}
      }),
    }
  },
  beforeMount() {
    this.$store.dispatch('apartmentsStore/GET_LIST');
  },
  computed: {
    ...mapGetters({
      apartmentsList: 'apartmentsStore/LIST',
      revenue: 'analyticsStore/REVENUE'
    }),
    apartments() {
      return this.apartmentsList
          ? Object.entries(this.apartmentsList).map(el => {
            return el[1].map(apartment => {
              return {id: apartment.id, label: apartment.name};
            });
          })[0]
          : [];
    },
    computedRevenue() {
      return this.revenue
          ? Object.entries(this.revenue).map((el) => {
            let dateArr = el[0].split('-');
            // let year = dateArr[0];
            let monthIdx = dateArr[1];

            return {
              date: el[0],
              N: monthIdx,
              month: this.months.find(el => el.id === parseInt(monthIdx)).label,
              bookings: el[1]['daysRented'] ?? 0,
              revenue: parseFloat(el[1]['revenue'] ?? 0).toFixed(2),
              utilities: {
                electricity: el[1]['electricity'] ?? 0,
                services: el[1]['servDeducate'] ?? 0,
                sum: parseFloat(el[1]['electricity'] + el[1]['water'] + el[1]['servDeducate']).toFixed(2),
                water: el[1]['water'] ?? 0
              },
              vat: parseFloat(el[1]['revenueVAT'] ?? 0).toFixed(2),
              balance: parseFloat(el[1]['toTransfer'] ?? 0).toFixed(2),
            };
          })
          : [];
    },
    months() {
      return [
        {id: 0, label: this.$t('month.all')},
        {id: 1, label: this.$t('month.january')},
        {id: 2, label: this.$t('month.february')},
        {id: 3, label: this.$t('month.march')},
        {id: 4, label: this.$t('month.april')},
        {id: 5, label: this.$t('month.may')},
        {id: 6, label: this.$t('month.june')},
        {id: 7, label: this.$t('month.july')},
        {id: 8, label: this.$t('month.august')},
        {id: 9, label: this.$t('month.september')},
        {id: 10, label: this.$t('month.october')},
        {id: 11, label: this.$t('month.november')},
        {id: 12, label: this.$t('month.december')}
      ];
    }
  },
  watch: {
    apartments(data) {
      this.filters.apartments = data.map(el => el.id);
      this.search();
    }
  },
  methods: {
    search() {
      this.$store.dispatch('analyticsStore/GET_REVENUE', {
        apartments: this.filters.apartments,   // [15132, 29168, 26954, 27002]
        year: this.filters.year
      });
    },
    goToBookingsList(data) {
      this.$router.push({name: 'bookingsList', query: {start_date: data.item.date + '-01'}});
    },
    showDetailsModal(data) {
      this.utilitiesModalData = data.item.utilities;
      this.$bvModal.show('utilitiesDetails');
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group::v-deep {
  position: relative;
  margin-bottom: 0 !important;

  & .form-group__label {
    position: absolute;
    top: -10px;
    left: 7px;
    background: #fff;
    z-index: 2;
  }
}
</style>